// Loading SAM JS breaks our tests, so we need to mock it.
class FakeAssetList {
  constructor() {
    this.monitoredAssetIDs = [];
    this.notify = null;
  }

  addAssetID(id) {
    this.monitoredAssetIDs.push(id);
  }

  on(event, handler) {
    if (event === 'notified') {
      this.notify = handler;
    }
  }

  off(event) {
    if (event === 'notified') {
      this.notify = null;
    }
  }
}

class FakeAssetLinkManager {
  constructor() {
    this.lists = [];
  }

  createList() {
    const list = new FakeAssetList();
    this.lists.push(list);
    return list;
  }
}

const FakeSAM = {
  Env: {
    Stage: 'stage',
    Production: 'production'
  },
  initializeSharedAssetModel: () => Promise.resolve({ assetLinkManager: new FakeAssetLinkManager() })
};

export default FakeSAM;
