import { getData } from './script_data.js';
import { del } from './secure_fetch.js';
const imsEvents = require('./ims_events.js');
import { EVENT_NAME as LIBRARY_CREATED_EVENT_NAME } from '@adobe-fonts/site/events/LibraryCreatedEvent.js';
import { EVENT_NAME as LIBRARY_FOLLOW_EVENT_NAME } from '@adobe-fonts/site/events/LibraryFollowEvent.js';
import { EVENT_NAME as SAVE_TO_LIBRARY_LOAD_EVENT } from '@adobe-fonts/site/events/SaveToLibraryMenuLoadedEvent.js';

const isTest = typeof window.__karma__ !== 'undefined';

const SAMJS = isTest ? require('./fake_sam.js').default : require('@ccx-public/shared-asset-model-js');
const SAMEnv = SAMJS.Env;
const initializeSharedAssetModel = SAMJS.initializeSharedAssetModel;

export default class LibrariesListener {
  constructor() {
    this.sam = null;
  }

  deleteCache(libraryId) {
    return del(`/libraries/cache?library_urns=${libraryId}`);
  }

  getAdobeIMS() {
    return window.adobeIMS;
  }

  getAdobeId() {
    return window.adobeid;
  }

  isDisabled() {
    return isTest;
  }

  listen() {
    if (this.isDisabled()) {
      return;
    }
    window.addEventListener(LIBRARY_CREATED_EVENT_NAME, (event) => this.deleteCache(event.detail.library.library_urn));
    window.addEventListener(LIBRARY_FOLLOW_EVENT_NAME, (event) => this.deleteCache(event.detail.libraryUrn));

    imsEvents.addOnReadyHandler(async () => {
      window.addEventListener(SAVE_TO_LIBRARY_LOAD_EVENT, async (event) => {
        await this.monitorLibraryUrns(event.detail.libraries.map((library) => library.library_urn));
      });
      const cachedLibraryUrns = getData('cached-library-urns');
      if (!cachedLibraryUrns || cachedLibraryUrns.length === 0) {
        // This also handles logged out users
        return;
      }
      await this.monitorLibraryUrns(cachedLibraryUrns);
    });
  }

  async monitorLibraryUrns(libraryUrns) {
    const sam = await this._getSam();
    const list = sam.assetLinkManager.lists[0] || sam.assetLinkManager.createList();
    for (const libraryUrn of libraryUrns) {
      if (list.monitoredAssetIDs.includes(libraryUrn)) {
        continue;
      }
      list.addAssetID(libraryUrn);
    }
    if (this.notifiedHandler) {
      list.off('notified', this.notifiedHandler);
    }
    this.notifiedHandler = this._handleNotified.bind(this);
    list.on('notified', this.notifiedHandler);
  }

  // This code is based on https://git.corp.adobe.com/torq/acpl-model/tree/main/modules/sam-js
  async _getSam() {
    if (this.sam) {
      return Promise.resolve(this.sam);
    }
    const adobeIMS = this.getAdobeIMS();
    const profile = await adobeIMS.getProfile();
    const adobeId = this.getAdobeId();
    this.sam = await initializeSharedAssetModel({
      CLIENT_ID: 'TypeKit1',
      USER_ID: profile.userId,
      ACCESS_TOKEN: adobeIMS.getAccessToken().token,
      ENVIRONMENT: adobeId.environment === 'stg1' ? SAMEnv.Stage : SAMEnv.Production,
      HOST_PRODUCT_DATA: {
        PRODUCT_ID: 'CCLibraries'
      }
    }, {
      refreshAccessToken: async () => {
        return adobeIMS.refreshToken();
      }
    });
    return this.sam;
  }

  _handleNotified(urns) {
    this.deleteCache(urns.join(','));
  }
}
